import { Grid, GridItem, Panel, PanelMain, PanelMainBody } from '@patternfly/react-core'
import { FetchQueryOptions, useQuery } from '@tanstack/react-query'
import { useContext, useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import {
  Production,
  ProductionAPI,
  ProductionDaily,
  ProductionMtd,
  ProductionYtd,
  WindFarms,
} from '../../client'
import DatetimeMonthPicker from '../../components/calendar/DatetimeMonthPicker'

import SitesContext from '../../contexts/SitesContext'
import { queryClient } from '../../services/api'
import calcVerticalHeight from '../../utils/calcVerticalHeight'
import DownloadButton from '../shared/DownloadButton'
import Page from '../shared/Page'
import SelectSite from '../shared/selects/SelectSite'
import ChartProductionDaily from './ChartProductionDaily'
import ChartProductionMonthly from './ChartProductionMonthly'
import ChartProductionToDate from './ChartProductionToDate'
import SelectWindFarms from './SelectWindFarms'

const defaultQueryOptions = {
  refetchOnWindowFocus: true,
  retry: false,
  staleTime: 1000 * 60 * 15,
}

const keysChartsProduction = {
  monthly: 'ChartProductionMonthly',
  monthToDate: 'ChartProductionMonthToDate',
  yearToDate: 'ChartProductionYearToDate',
  daily: 'ChartProductionDaily',
}

const onKeyChartProduction = (
  type: keyof typeof keysChartsProduction,
  {
    siteId,
    wfId,
  }: {
    siteId: number
    wfId: number
  }
) => [keysChartsProduction[type], siteId, wfId]

const prefetch = async (site_id: number, wf_id: string, queryOptions: FetchQueryOptions) => {
  const params = { siteId: site_id, wfId: Number(wf_id) }

  await queryClient.prefetchQuery({
    queryKey: onKeyChartProduction('monthly', params),
    queryFn: () => ProductionAPI.get(params),
    ...queryOptions,
  })
  await queryClient.prefetchQuery({
    queryKey: onKeyChartProduction('monthToDate', params),
    queryFn: () => ProductionAPI.getMonthToDate(params),
    ...queryOptions,
  })
  await queryClient.prefetchQuery({
    queryKey: onKeyChartProduction('yearToDate', params),
    queryFn: () => ProductionAPI.getYearToDate(params),
    ...queryOptions,
  })
  await queryClient.prefetchQuery({
    queryKey: onKeyChartProduction('daily', params),
    queryFn: () => ProductionAPI.getDaily(params),
    ...queryOptions,
  })
}

const Producao = () => {
  const {
    site,
    sites,
    isLoading: sitesIsLoading,
    windFarms: _windFarms,
    setActiveSite,
  } = useContext(SitesContext)
  const [isNavOpen, setNavOpen] = useState(window.innerHeight < window.innerWidth)

  const [searchParams] = useSearchParams()
  const modoExibicao = searchParams.get('telao')
  const { siteName } = useParams()
  const navigate = useNavigate()

  useEffect(() => {
    if (sitesIsLoading || !siteName) return
    const newSite = sites.find(s => s.site.toLowerCase() === siteName.toLowerCase())
    if (!newSite) return
    setActiveSite(newSite.site_id)
  }, [sitesIsLoading, sites, siteName])

  useEffect(() => {
    if (!site || !site.site || sitesIsLoading) return
    navigate(`/producao/${site?.site?.toLowerCase()}`, { replace: true })
  }, [site, sitesIsLoading])

  const dtNow = new Date()
  const windFarms = [{ wf: 'Complexo', wf_id: '0' } as unknown as WindFarms, ..._windFarms]

  const minDate = new Date(site.commissioning_date)
  const [selectedDate, setSelectedDate] = useState(new Date())

  const [selectedWindFarm, setSelectedWindFarm] = useState('0')
  const [monthlyData, setMonthlyData] = useState<Production[]>([])
  const [monthToDateData, setMonthToDateData] = useState<ProductionMtd[]>([])
  const [yearToDateData, setYearToDateData] = useState<ProductionYtd[]>([])
  const [dailyData, setDailyData] = useState<ProductionDaily[]>([])

  const handleChangeSite = () => {
    setSelectedDate(new Date())
    setSelectedWindFarm('0')
  }
  const queryOptions = { ...defaultQueryOptions, enabled: !!site?.site_id }

  const paramsQuery = useMemo(
    () => ({ siteId: site?.site_id, wfId: Number(selectedWindFarm) }),
    [site?.site_id, selectedWindFarm]
  )

  const { isLoading: monthlyDataIsLoading, data: _monthlyData } = useQuery({
    queryKey: onKeyChartProduction('monthly', paramsQuery),
    queryFn: () => ProductionAPI.get(paramsQuery),
    ...queryOptions,
  })
  const { isLoading: monthToDateDataIsLoading, data: _monthToDateData } = useQuery({
    queryKey: onKeyChartProduction('monthToDate', paramsQuery),
    queryFn: () => ProductionAPI.getMonthToDate(paramsQuery),
    ...queryOptions,
  })
  const { isLoading: yearToDateDataIsLoading, data: _yearToDateData } = useQuery({
    queryKey: onKeyChartProduction('yearToDate', paramsQuery),
    queryFn: () => ProductionAPI.getYearToDate(paramsQuery),
    ...queryOptions,
  })
  const { isLoading: dailyDataIsLoading, data: _dailyData } = useQuery({
    queryKey: onKeyChartProduction('daily', paramsQuery),
    queryFn: () => ProductionAPI.getDaily(paramsQuery),
    ...queryOptions,
  })

  const isLoading =
    monthlyData.length === 0 ||
    monthToDateData.length === 0 ||
    yearToDateData.length === 0 ||
    yearToDateData.length === 0 ||
    sitesIsLoading

  const isContentLoading =
    (monthlyDataIsLoading ||
      monthToDateDataIsLoading ||
      yearToDateDataIsLoading ||
      dailyDataIsLoading) &&
    monthlyData.length !== 0

  useEffect(() => {
    if (
      monthlyDataIsLoading ||
      monthToDateDataIsLoading ||
      yearToDateDataIsLoading ||
      dailyDataIsLoading ||
      sitesIsLoading
    )
      return

    setMonthlyData(_monthlyData || [])
    setMonthToDateData(_monthToDateData || [])
    setYearToDateData(_yearToDateData || [])
    setDailyData(_dailyData || [])
  }, [
    monthlyDataIsLoading,
    monthToDateDataIsLoading,
    yearToDateDataIsLoading,
    dailyDataIsLoading,
    sitesIsLoading,
  ])

  useEffect(() => {
    if (isLoading) return
    sites
      .filter(s => s.site_id !== site.site_id)
      .forEach(s => prefetch(s.site_id, selectedWindFarm, queryOptions))
  }, [isLoading, sites, selectedWindFarm, JSON.stringify(queryOptions)])

  const plotStyle = {
    width: '100%',
    height: calcVerticalHeight({
      gridSize: 2,
      elementSize: 1,
      spacerSize: 'sm',
      customOffset: modoExibicao === 'telao' ? '1rem' : '3.1rem',
    }),
    minHeight: '15rem',
  }
  const revision = parseInt(String(Number(isNavOpen) + Number(true)))

  return (
    <Page
      pageName='Produção'
      siteName={site?.site_name}
      siteId={site?.site_id}
      isNavOpen={isNavOpen}
      setNavOpen={setNavOpen}
      isLoading={isLoading}
      isContentLoading={isContentLoading}
      filters={[
        <SelectSite key='SelectListSites' enableUrlParams onChange={handleChangeSite} />,
        <DatetimeMonthPicker
          key='DatetimeMonthPicker'
          date={selectedDate}
          minEnabled={minDate}
          maxEnabled={dtNow}
          onChange={setSelectedDate}
        />,
        <SelectWindFarms
          key='SelectWindFarms'
          selectedWindFarm={selectedWindFarm}
          windFarms={windFarms}
          onChange={setSelectedWindFarm}
          isLoading={sitesIsLoading}
        />,
        <DownloadButton
          key={`dl_btn_${site?.site_name}`}
          label='Exportar'
          url={`/sites/${site.site_id}/production/report?${new URLSearchParams({
            mm: String(selectedDate.getMonth() + 1),
            yyyy: String(selectedDate.getFullYear()),
            wf_id: selectedWindFarm,
          })}`}
          filename={`report_production_${site.site_name}_${
            Number(selectedWindFarm) > 0 ? selectedWindFarm : ''
          }_${selectedDate.getFullYear()}_${selectedDate.getMonth() + 1}.xlsx`}
        />,
      ]}
    >
      <Panel className='pf-v5-u-box-shadow-sm'>
        <PanelMain>
          <PanelMainBody>
            <Grid>
              <GridItem lg={8} md={12}>
                <ChartProductionMonthly
                  monthlyData={monthlyData}
                  year={selectedDate.getFullYear()}
                  month={selectedDate.getMonth() + 1}
                  revision={revision}
                  plotStyle={plotStyle}
                  isLoading={isLoading}
                />
              </GridItem>
              <GridItem lg={2} md={12}>
                <ChartProductionToDate
                  prodToDateData={monthToDateData}
                  year={selectedDate.getFullYear()}
                  month={selectedDate.getMonth() + 1}
                  revision={revision}
                  plotStyle={plotStyle}
                  type='mtd'
                  isLoading={isLoading}
                />
              </GridItem>
              <GridItem lg={2} md={12}>
                <ChartProductionToDate
                  prodToDateData={yearToDateData}
                  year={selectedDate.getFullYear()}
                  month={selectedDate.getMonth() + 1}
                  revision={revision}
                  plotStyle={plotStyle}
                  type='ytd'
                  isLoading={isLoading}
                />
              </GridItem>
              <GridItem lg={12} md={12}>
                <ChartProductionDaily
                  dailyData={dailyData}
                  year={selectedDate.getFullYear()}
                  month={selectedDate.getMonth() + 1}
                  revision={revision}
                  plotStyle={plotStyle}
                  isLoading={isLoading}
                />
              </GridItem>
            </Grid>
          </PanelMainBody>
        </PanelMain>
      </Panel>
    </Page>
  )
}

export default Producao
