import { sum } from 'd3-array'
import { useContext } from 'react'
import SitesContext from '../../contexts/SitesContext'
import ColorScale from '../../utils/colorScale'
import { filterYearMonth } from '../../utils/formatDate'
import { defaultAxis } from '../../utils/plotly'
import Plot from '../shared/Plot'
import {
  categories,
  DEFAULT_PLOT_CONFIG,
  PLOT_STYLE,
  PLOT_TEMPORAL_MONTHLY_TURBS,
} from './constants'
import useData from './useData'

const PLOT_LAYOUT = {
  paper_bgcolor: ColorScale.branco,
  plot_bgcolor: ColorScale.branco_clear,
  showlegend: true,
  hovermode: 'x unified',
  autosize: true,
  barmode: 'stack',
  margin: { b: 40, t: 10, r: 10, l: 40 },
  xaxis: {
    ...defaultAxis,
    fixedrange: true,
    mirror: 'ticks',
    linewidth: 2,
    gridwidth: 2,
    title: 'Mês',
    type: 'category',
  },
  yaxis: { ...defaultAxis, fixedrange: true, ticksuffix: '%', range: [0, 110] },
}

const PlotMonthlyTurbTotal = ({
  isLoading,
  dateIn,
  dateFin,
  turbId,
  revision,
  categorySelection,
}: {
  isLoading: boolean
  dateIn: Date
  dateFin: Date
  turbId: number
  revision: number
  categorySelection: categories
}) => {
  const {
    isLoading: dataIsLoading,
    monthlyTurbs: allMonthlyTurbs,
    plotMonths,
    plotMonthsLabels,
  } = useData(dateIn, dateFin)
  const { turbsMap } = useContext(SitesContext)

  const _defaultAxis = { ...defaultAxis, x: plotMonthsLabels, type: 'bar', hoverinfo: 'all' }

  const turb = turbsMap.get(turbId)

  const monthlyTurbs = allMonthlyTurbs.filter(e => e.turb_id === turbId)

  if (isLoading || dataIsLoading || !turb || monthlyTurbs.length === 0) {
    return (
      <div style={PLOT_STYLE} className='pf-v5-u-mt-0'>
        <Plot
          divId={PLOT_TEMPORAL_MONTHLY_TURBS}
          useResizeHandler
          key={revision}
          data={[]}
          layout={PLOT_LAYOUT}
          config={DEFAULT_PLOT_CONFIG}
          style={PLOT_STYLE}
        />
      </div>
    )
  }

  const y = plotMonths.map(ym => {
    const [yyyy, mm] = ym

    const ymData = monthlyTurbs.find(m => filterYearMonth(m.ts, yyyy, mm))

    if (!ymData) return null

    const downtime = sum(categorySelection.downtime.map(cat => ymData[cat]))
    const online = sum(categorySelection.online.map(cat => ymData[cat]))

    return (online / (online + downtime)) * 100
  })

  const data = [
    {
      ..._defaultAxis,
      y,
      name: 'Disponibilidade Temporal',
      type: 'bar',
      marker: { color: ColorScale.verde },
    },
  ]

  return (
    <div style={PLOT_STYLE} className='pf-v5-u-mt-0'>
      <Plot
        divId={PLOT_TEMPORAL_MONTHLY_TURBS}
        useResizeHandler
        key={revision}
        data={data}
        layout={PLOT_LAYOUT}
        config={DEFAULT_PLOT_CONFIG}
        style={PLOT_STYLE}
        onLegendClick={() => false}
      />
    </div>
  )
}

export default PlotMonthlyTurbTotal
