import { sum } from 'd3-array'
import months from '../../utils/months'
import { defaultAxis, layoutTemplate } from '../../utils/plotly'

import ColorScale from '../../utils/colorScale'
import { filterYearMonth } from '../../utils/formatDate'
import Plot from '../shared/Plot'
import { categories, DEFAULT_PLOT_CONFIG, PLOT_STYLE, PLOT_TEMPORAL_MONTHLY } from './constants'
import useData from './useData'

const PLOT_LAYOUT = {
  template: layoutTemplate,
  paper_bgcolor: ColorScale.branco,
  plot_bgcolor: ColorScale.branco_clear,
  showlegend: false,
  hovermode: 'x unified',
  autosize: true,
  barmode: 'stack',
  margin: { b: 40, t: 10, r: 10, l: 40 },
  xaxis: {
    ...defaultAxis,
    fixedrange: true,
    mirror: 'ticks',
    linewidth: 2,
    gridwidth: 2,
    title: 'Mês',
    type: 'category',
  },
  yaxis: { ...defaultAxis, fixedrange: true, ticksuffix: '%', range: [0, 110] },
}

const PlotMonthlyTotal = ({
  isLoading,
  dateIn,
  dateFin,
  revision,
  categorySelection,
}: {
  isLoading: boolean
  dateIn: Date
  dateFin: Date
  revision: number
  categorySelection: categories
}) => {
  const {
    isLoading: dataIsLoading,
    monthly,
    plotMonths,
    plotMonthsLabels,
  } = useData(dateIn, dateFin)

  const _defaultAxis = { ...defaultAxis, x: plotMonthsLabels, type: 'bar', hoverinfo: 'all' }

  const y = plotMonths.map(ym => {
    const [yyyy, mm] = ym

    const ymData = monthly.find(m => filterYearMonth(m.ts, yyyy, mm))

    if (!ymData) return undefined

    const downtime = sum(categorySelection.downtime.map(cat => ymData[cat]))
    const online = sum(categorySelection.online.map(cat => ymData[cat]))

    return (online / (online + downtime)) * 100
  })

  const data = [
    {
      ..._defaultAxis,
      y,
      name: 'Disponibilidade Temporal',
      type: 'bar',
      marker: {
        color: ColorScale.verde,
      },
    },
  ]

  if (isLoading || dataIsLoading) {
    return (
      <div style={PLOT_STYLE} className='pf-v5-u-mt-0'>
        <Plot
          divId={PLOT_TEMPORAL_MONTHLY}
          useResizeHandler
          key={revision}
          data={[]}
          layout={PLOT_LAYOUT}
          config={DEFAULT_PLOT_CONFIG}
          style={PLOT_STYLE}
        />
      </div>
    )
  }

  return (
    <div style={PLOT_STYLE} className='pf-v5-u-mt-0'>
      <Plot
        divId={PLOT_TEMPORAL_MONTHLY}
        useResizeHandler
        key={revision}
        data={data}
        layout={PLOT_LAYOUT}
        config={DEFAULT_PLOT_CONFIG}
        style={PLOT_STYLE}
        onLegendClick={() => false}
      />
    </div>
  )
}

export default PlotMonthlyTotal
