import {
  Panel,
  PanelMain,
  PanelMainBody,
  Split,
  SplitItem,
  Tab,
  Tabs,
  TabTitleText,
} from '@patternfly/react-core'
import { useContext, useEffect, useState } from 'react'
import PanelSickHeader from '../../components/PanelStickyHeader'
import SitesContext from '../../contexts/SitesContext'
import { categories } from './constants'
import PlotDailyWindFarm from './PlotDailyWindFarm'
import PlotDailyWindFarmTotal from './PlotDailyWindFarmTotal'
import PlotMonthlyWindFarm from './PlotMonthlyWindFarm'
import PlotMonthlyWindFarmTotal from './PlotMonthlyWindFarmTotal'
import PlotPeriodPark from './PlotPeriodPark'
import PlotPeriodParkTotal from './PlotPeriodParkTotal'

const PanelWindFarm = ({
  dateIn,
  dateFin,
  revision,
  categorySelection,
  activeType,
}: {
  dateIn: Date
  dateFin: Date
  revision: number
  categorySelection: categories
  activeType: 'Total' | 'Por Categoria'
}) => {
  const { windFarms, isLoading: sitesIsLoading, site } = useContext(SitesContext)
  const [activeParkKey, setActiveParkKey] = useState<number | string>(1)

  useEffect(() => {
    if (windFarms.length === 0 || sitesIsLoading) return
    setActiveParkKey(windFarms[0].wf_id)
  }, [site])

  return (
    <Panel className='pf-v5-u-box-shadow-sm'>
      <PanelMain>
        <PanelSickHeader title='Disponibilidade Temporal Por Parque' />

        <PanelMainBody>
          {activeType === 'Total' ? (
            <PlotPeriodParkTotal
              isLoading={sitesIsLoading}
              dateIn={dateIn}
              dateFin={dateFin}
              revision={revision}
              categorySelection={categorySelection}
            />
          ) : (
            <PlotPeriodPark
              isLoading={sitesIsLoading}
              dateIn={dateIn}
              dateFin={dateFin}
              revision={revision}
            />
          )}

          <Tabs activeKey={activeParkKey} onSelect={(_, tabIndex) => setActiveParkKey(tabIndex)}>
            {windFarms.map(windFarm => (
              <Tab
                key={`${windFarm.site_id}${windFarm.wf}`}
                eventKey={windFarm.wf_id}
                title={<TabTitleText> {windFarm.wf} </TabTitleText>}
              />
            ))}
          </Tabs>
          <Split hasGutter isWrappable>
            <SplitItem isFilled>
              {activeType === 'Total' ? (
                <PlotDailyWindFarmTotal
                  isLoading={sitesIsLoading}
                  dateIn={dateIn}
                  dateFin={dateFin}
                  windFarmId={activeParkKey as number}
                  revision={revision}
                  categorySelection={categorySelection}
                />
              ) : (
                <PlotDailyWindFarm
                  isLoading={sitesIsLoading}
                  dateIn={dateIn}
                  dateFin={dateFin}
                  windFarmId={activeParkKey as number}
                  revision={revision}
                />
              )}
            </SplitItem>
            <SplitItem isFilled>
              {activeType === 'Total' ? (
                <PlotMonthlyWindFarmTotal
                  isLoading={sitesIsLoading}
                  dateIn={dateIn}
                  dateFin={dateFin}
                  windFarmId={activeParkKey as number}
                  revision={revision}
                  categorySelection={categorySelection}
                />
              ) : (
                <PlotMonthlyWindFarm
                  isLoading={sitesIsLoading}
                  dateIn={dateIn}
                  dateFin={dateFin}
                  windFarmId={activeParkKey as number}
                  revision={revision}
                />
              )}
            </SplitItem>
          </Split>
        </PanelMainBody>
      </PanelMain>
    </Panel>
  )
}
export default PanelWindFarm
