import { sum } from 'd3-array'
import { useMemo } from 'react'
import ColorScale from '../../utils/colorScale'
import { filterYearMonth } from '../../utils/formatDate'
import { defaultAxis, layoutTemplate } from '../../utils/plotly'
import Plot from '../shared/Plot'
import {
  COUNTERDATA_TYPES,
  COUNTERDATA_TYPE_LIST,
  DEFAULT_PLOT_CONFIG,
  PLOT_STYLE,
  PLOT_TEMPORAL_MONTHLY,
} from './constants'
import useData from './useData'

const PLOT_LAYOUT = {
  template: layoutTemplate,
  paper_bgcolor: ColorScale.branco,
  plot_bgcolor: ColorScale.branco_clear,
  showlegend: false,
  hovermode: 'x unified',
  autosize: true,
  barmode: 'stack',
  margin: { b: 40, t: 10, r: 10, l: 40 },
  xaxis: {
    ...defaultAxis,
    fixedrange: true,
    mirror: 'ticks',
    linewidth: 2,
    gridwidth: 2,
    title: 'Mês',
    type: 'category',
  },
  yaxis: { ...defaultAxis, fixedrange: true, ticksuffix: '%', range: [0, 110] },
}

const PlotMonthly = ({
  isLoading,
  dateIn,
  dateFin,
  revision,
}: {
  isLoading: boolean
  dateIn: Date
  dateFin: Date
  revision: number
}) => {
  const {
    isLoading: dataIsLoading,
    monthly,
    plotMonths,
    plotMonthsLabels,
  } = useData(dateIn, dateFin)

  const _defaultAxis = { ...defaultAxis, x: plotMonthsLabels, type: 'bar', hoverinfo: 'all' }

  const monthlyTotals = new Map<number, number>()

  plotMonths.forEach(ym => {
    const [yyyy, mm] = ym
    const ymData = monthly.find(m => filterYearMonth(m.ts, yyyy, mm))
    monthlyTotals.set(
      yyyy * 100 + mm,
      ymData ? sum(COUNTERDATA_TYPE_LIST.map(cat => ymData[cat])) : 0
    )
  })

  const data = useMemo(() => {
    if (isLoading || dataIsLoading) return []

    const bars = COUNTERDATA_TYPE_LIST.map(cat => {
      const yAxis = plotMonths.map(ym => {
        const [yyyy, mm] = ym

        const yValue = monthly.find(m => filterYearMonth(m.ts, yyyy, mm))

        if (!yValue) return undefined
        const catTotal = monthlyTotals.get(yyyy * 100 + mm) ?? 1

        return Math.round(((yValue[cat] ?? 0) / catTotal) * 10000) / 100
      })

      return {
        ..._defaultAxis,
        y: yAxis,
        name: COUNTERDATA_TYPES[cat].description,
        marker: { color: COUNTERDATA_TYPES[cat].color },
      }
    })

    return bars
  }, [monthly, dateIn, dateFin, revision, isLoading, dataIsLoading])

  if (isLoading || dataIsLoading) {
    return (
      <div style={PLOT_STYLE} className='pf-v5-u-mt-0'>
        <Plot
          divId={PLOT_TEMPORAL_MONTHLY}
          useResizeHandler
          key={revision}
          data={[]}
          layout={PLOT_LAYOUT}
          config={DEFAULT_PLOT_CONFIG}
          style={PLOT_STYLE}
        />
      </div>
    )
  }

  return (
    <div style={PLOT_STYLE} className='pf-v5-u-mt-0'>
      <Plot
        divId={PLOT_TEMPORAL_MONTHLY}
        useResizeHandler
        key={revision}
        data={data}
        layout={PLOT_LAYOUT}
        config={DEFAULT_PLOT_CONFIG}
        style={PLOT_STYLE}
        onLegendClick={() => false}
      />
    </div>
  )
}

export default PlotMonthly
